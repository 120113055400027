import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as _ from 'lodash';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {RefModelTogglesType} from '../../../util/enum';
import {Q9CustomEncoder, Q9ParamsInterface} from '@q9elements/ui-core';

@Injectable({
  providedIn: 'root'
})
export class RefModelsSharedDaoService {
  apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.API_ENDPOINT;
  }

  getList(params: Q9ParamsInterface): Observable<any> {
    const Params: any = this.composeRequestParams(params);
    return this.http.get<any>(`${this.apiUrl}/refmodels`, {params: Params});
  }

  update(id, data: {}) {
    return this.http.put(`${this.apiUrl}/refmodel/${id}`, data, {responseType: 'text'});
  }

  refModelUsers(row: any, params: {skip: number; limit: number; sort?: string; search?: string}) {
    const Params = this.composeRequestParams(params);
    return this.http.get<any>(`${this.apiUrl}/refmodel/${row.id}/user`, {params: Params}).pipe(
      map(response => {
        const users = _.map(response.users, (perm: any) => {
          perm.canCopy = perm.canCopy === 1;
          perm.canGrantAccess = perm.canGrantAccess === 1;
          perm.canChangeFingerprint = perm.canChangeFingerprint === 1;
          return perm;
        });
        return {
          owner: response.refModelOwner,
          data: users,
          total: response.count,
          row: row
        };
      })
    );
  }

  updateToggleState(
    data: {
      id: string;
      gdprEnabled?: boolean;
      dataPopulationV3Enabled?: boolean;
    },
    toggleTarget: RefModelTogglesType
  ) {
    return this.http.put(`${this.apiUrl}/refmodel/${data.id}/toggle-update/${toggleTarget}`, data, {
      responseType: 'text'
    });
  }

  updateAccess(data: {id: string; viewAll: boolean}) {
    return this.http.put(`${this.apiUrl}/refmodel/${data.id}/access`, data, {responseType: 'text'});
  }

  updateRefModelRights(refModelId: any, userId: any, body): Observable<any> {
    const url = `${this.apiUrl}/refmodel/${refModelId}/user/${userId}`;
    return this.http.put(url, body).pipe(catchError(this.handleError));
  }

  deleteUserFromRefModel(refModelId: string, userId: any): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}/refmodel/${refModelId}/user/${userId}`)
      .pipe(catchError(this.handleError));
  }

  refModelOwnerCandidates(refModelId: string, search: string): Observable<any> {
    const params = this.composeRequestParams({search});
    return this.http.get(`${this.apiUrl}/refmodel/${refModelId}/candidates/owners`, {params});
  }

  changeOwner(refModelId: string, author: any) {
    return this.http
      .put(`${this.apiUrl}/refmodel/${refModelId}/author`, {author})
      .pipe(catchError(this.handleError));
  }

  getGdprStatistic(refModelId: string, queryParams?) {
    /** If duration is not provided it returns current statuses
     *  If type is not provided it returns stat by gdpr statuses */
    return this.http
      .get(`${this.apiUrl}/refmodel/${refModelId}/gdpr/stat`, {params: queryParams})
      .pipe(catchError(this.handleError))
      .toPromise();
  }

  inviteIntoRefModel(id: string, addedUser: {}, role: string, message: string, isEmail: boolean) {
    return this.http
      .post(`${this.apiUrl}/refmodel/${id}/user`, {id, message, addedUser, role, isEmail})
      .pipe(catchError(this.handleError));
  }

  suggestInvite(refModelId: string, search: string): Observable<any> {
    const params = this.composeRequestParams({search});
    return this.http.get(`${this.apiUrl}/refmodel/${refModelId}/candidates/access`, {params});
  }

  updateAdditionalEmail(refModelId: string, email: string) {
    const url = `${this.apiUrl}/refmodel/${refModelId}/additional-email`;

    return this.http.put(url, {email}).pipe(catchError(this.handleError));
  }

  checkUserAsStakeholderInRM(rmId: string, userId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/refmodel/${rmId}/userAsStakeholder/${userId}`);
  }

  private composeRequestParams(params: Q9ParamsInterface): HttpParams {
    // Initialize Params Object
    let Params = new HttpParams({encoder: new Q9CustomEncoder()});
    // Assigning parameters
    _.forIn(params, function (value, key) {
      Params = Params.append(key, `${value}`);
    });
    return Params;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
